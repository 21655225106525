import { getStyleProps, getStylingConfig } from './utils';
import ChatButton from './chatButton';
import ChatApp from './chatApp';
import ChatPopup from './chatPopup';

export default class ChatContainer {
  constructor(props = {}) {
    this.open = false;
    this.chatButton = null;
    this.chatApp = null;
    this.chatPopup = null;

    // Image URL passed through embed script params (temporary)
    this.buttonImageURL = props?.imageURL
      ? `${props.imageURL}?format=auto&w=100&h=100`
      : null;

    this.styleConfig = getStylingConfig(props);
  }

  resetStylingConfig = (params) => {
    if (params?.position) {
      this.chatButton.resetStyles();
      this.chatApp.resetStyles();
      this.chatPopup.resetStyles();
    }
  };

  setStylingConfig = (params) => {
    const updatedProps = getStyleProps(params, this.styleConfig);

    const horizontalPos =
      (updatedProps.position === 'left' && 'left') || 'right';

    this.chatApp.updateStyles({
      zIndex: updatedProps.zIndex,
      bottom: updatedProps.verticalSpacing,
      positionType: horizontalPos,
      position: updatedProps.horizontalSpacing,
    });

    this.chatButton.updateStyles({
      bottom: updatedProps.verticalSpacing,
      positionType: horizontalPos,
      position: updatedProps.horizontalSpacing,
      width: updatedProps.size,
      height: updatedProps.size,
    });

    this.chatPopup.updateStyles({
      bottom: updatedProps.verticalSpacing,
      positionType: horizontalPos,
      position: updatedProps.horizontalSpacing,
      buttonSize: updatedProps.size,
    });

    this.styleConfig = { ...updatedProps };
  };

  initialize = (config) => {
    const { showOnInit, theme, buttonStyles, zIndex } = this.styleConfig;

    // Main container.........................
    const container = document.createElement('div');
    container.classList.add('akin-ai-chat');
    document.body.appendChild(container);

    // Chat bubble button container......................
    this.chatButton = this.initChatButton(config.popup?.websiteBubble, {
      showOnInit,
      theme,
    });

    // Chat popup............
    if (config?.popup && config?.popup?.components) {
      this.chatPopup = this.initChatPopup(config.popup.components);
    }

    // Iframe chat-app container...
    this.chatApp = new ChatApp();

    // handle dynamic styling config for chat button and container.......
    this.setStylingConfig({ ...buttonStyles, zIndex });

    // Appending Iframe message container and button container to main container..
    container.appendChild(this.chatApp.ele);
    container.appendChild(this.chatPopup.ele);
    container.appendChild(this.chatButton.ele);
  };

  initChatButton = (config, options) => {
    const chatButton = new ChatButton({
      showOnInit: options?.showOnInit,
      theme: options?.theme,
    });

    chatButton.ele.addEventListener('click', this.toggleOpen.bind(this));

    if (!config?.url && this.buttonImageURL) {
      chatButton.create({ type: 'image', url: this.buttonImageURL });
    } else {
      chatButton.create(config);
    }

    return chatButton;
  };

  initChatPopup = (components) => {
    const chatPopup = new ChatPopup();
    chatPopup.ele.addEventListener('click', this.toggleOpen.bind(this));

    components.forEach((component) => {
      chatPopup.create(component.type, component);
    });

    return chatPopup;
  };

  toggleOpen = () => {
    this.open = !this.open;
    if (this.open) {
      this.chatApp.show();
      this.chatButton.hide();

      if (this.chatPopup?.ele) {
        this.chatPopup.hide();
      }
    } else {
      this.chatApp.hide();
      this.chatButton.show();
    }
  };

  hide = () => {
    this.chatApp.ele.style.display = 'none';
    this.chatButton.hide();
    if (this.open) this.toggleOpen();
  };

  show = () => {
    this.chatApp.ele.style.display = 'unset';
    this.chatButton.show();
  };

  destroy = () => {
    this.chatButton.destroy();
    this.chatApp.destroy();
  };
}
