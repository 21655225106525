import {
  ALLOWED_STYLING_PROPS,
  DEFAULT_STYLE_PROPS,
  POPUP_TYPES,
} from './constants';

export function toPixel(number) {
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  if (Number.isNaN(number) || Number.isNaN(parseFloat(number))) return;
  return `${number}px`;
}

export function getStyleProps(
  params = {},
  defaultParams = { ...DEFAULT_STYLE_PROPS }
) {
  try {
    const styleProps = Object.keys(params).reduce(
      (prev, curr) => {
        if (ALLOWED_STYLING_PROPS.indexOf(curr) === -1) {
          throw Error(
            `Prop "${curr}" is not supported. Supported props include [${ALLOWED_STYLING_PROPS.join(
              ', '
            )}]`
          );
        }

        if (prev?.[curr] && params?.[curr]) {
          // eslint-disable-next-line no-param-reassign
          prev[curr] = params[curr];
        }

        return prev;
      },
      { ...defaultParams }
    );

    return { ...styleProps };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { ...defaultParams };
  }
}

export function getStylingConfig(data = {}) {
  let config = {
    showOnInit: true,
    theme: data?.theme || '#000',
  };

  if (data?.buttonStyles) {
    config = { ...data.buttonStyles };
  }

  if (data?.zIndex) {
    config.zIndex = data.zIndex;
  }

  if ('showOnInit' in data) {
    config.showOnInit = data.showOnInit;
  }

  return { ...DEFAULT_STYLE_PROPS, ...config };
}

export function getFileUrl(imageKey) {
  if (!imageKey) return null;
  return `${process.env.ASSETS_BASE_URL}/${imageKey}`;
}

export function processInitConfig(config) {
  const data = {
    isVisible: config?.is_visible,
    popup: { websiteBubble: null, components: [] },
  };

  if (config?.popup?.website_bubble?.url) {
    data.popup.websiteBubble = {
      type: config.popup.website_bubble.type,
      url: `${getFileUrl(
        config.popup.website_bubble.url
      )}?format=auto&w=100&h=100`,
    };
  }

  if (config?.popup?.components?.length > 0) {
    data.popup.components = config.popup.components
      .filter((component) => component?.is_active)
      .map((component) => {
        if (component.type === POPUP_TYPES.text) {
          return {
            type: component.type,
            text: component.text,
            bgColor: component.style.bg_color,
            accentColor: component.style.accent_color,
            textColor: component.style.text_color,
            timing: component.timing, // in seconds,
            isActive: component.is_active,
          };
        }

        return {
          type: component.type,
          mediaURL: getFileUrl(component.media_key),
          timing: component.timing, // in seconds
          isActive: component.is_active,
        };
      });
  }

  return { ...data };
}
